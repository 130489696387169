<template>
  <div>
    <MainComponent />
  </div>
</template>

<script>
import MainComponent from '@/components/main/MainComponent.vue';

export default {
  components: {
    MainComponent,
  },
};
</script>

<style></style>
