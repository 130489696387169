<template>
  <div class="row content-wrapper">
    <LoadingSpinner v-if="isLoading" />
    <template v-else>
      <CarouselComponent />
      <!-- 고정 콘텐츠 -->
      <template v-if="this.page == 1">
        <div class="col-md-4 content-list-wrapper">
          <div class="content-item-wrapper">
            <a href="/destination/147?page=1">
              <img
                src="http://dantwortjejutovr360.s3.ap-northeast-2.amazonaws.com/places/%EB%A0%88%EC%9D%BC%EB%B0%94%EC%9D%B4%ED%81%AC/1537250091806-main_690x720.jpg"
                alt=""
                class="list-thumbnail"
              />
              <h4 class="spot-title">레일바이크</h4>
              <p class="spot-desc">1,000원으로 동물 먹이주기 체험도 가능!</p>
            </a>
          </div>
        </div>
        <div class="col-md-4 content-list-wrapper">
          <div class="content-item-wrapper">
            <a href="/destination/2?page=1">
              <img
                src="http://post.phinf.naver.net/MjAxNzEwMjZfMTI2/MDAxNTA5MDEwNzg1NTUy.cG8OyPxel3lL-OR1yr6vR9qmRbN4uXuPMPr6_X5Joh0g.E5lwqfjZgIS8zsxH1a92p_TDh0uij9H2ZRykUQVmHfUg.PNG/main_690x720.png?type=w1200"
                alt=""
                class="list-thumbnail"
              />
              <h4 class="spot-title">카멜리아힐</h4>
              <p class="spot-desc">
                햇빛도 피하고 사진 소품으로도 좋은 모자 챙기기
              </p>
            </a>
          </div>
        </div>
        <div class="col-md-4 content-list-wrapper">
          <div class="content-item-wrapper">
            <a href="/destination/84?page=1">
              <img
                src="http://post.phinf.naver.net/MjAxNzA3MjFfMjI4/MDAxNTAwNjAwMDc5MTg5.JGuS-HhBqYY0WUnxg0MZJdDs7BoZEmOmj9UP2GdXzK0g.Z1bOEb6TvjQzEWLc0KjN6X2Lj7GwgqAFv8MsyPNzz1wg.JPEG/main_690x720_%2824%29.jpg?type=w1200"
                alt=""
                class="list-thumbnail"
              />
              <h4 class="spot-title">블랑로쉐</h4>
              <p class="spot-desc">우도의 특산 땅콩으로 만든 디저트 맛보기</p>
            </a>
          </div>
        </div>
        <div class="col-md-4 content-list-wrapper">
          <div class="content-item-wrapper">
            <a href="https://bit.ly/2XLZ338" target="_blank">
              <img
                src="https://dantwortjejutovr360.s3.ap-northeast-2.amazonaws.com/banners/%EC%A0%9C%EC%A3%BC%ED%8C%A8%EC%8A%A4%EB%A0%8C%ED%8A%B8%EC%B9%B4/jejurentcar_middle_200810.jpg"
                alt=""
                class="list-thumbnail"
              />
            </a>
          </div>
        </div>
        <div class="col-md-4 content-list-wrapper">
          <div class="content-item-wrapper">
            <a href="/destination/11?page=1">
              <img
                src="http://s3.ap-northeast-2.amazonaws.com/dantwortjejutovr360/Thumbnail/%EB%B3%B4%EB%A1%AC%EC%99%93/main_690x720.png"
                alt=""
                class="list-thumbnail"
              />
              <h4 class="spot-title">보롬왓</h4>
              <p class="spot-desc">여름엔 수국, 봄.가을엔 메밀꽃이 가득하다</p>
            </a>
          </div>
        </div>
        <div class="col-md-4 content-list-wrapper">
          <div class="content-item-wrapper">
            <a href="/destination/107?page=1">
              <img
                src="http://s3.ap-northeast-2.amazonaws.com/dantwortjejutovr360/Thumbnail/%EC%A0%9C%EB%9D%BC%EC%A7%84%EC%98%A4%ED%94%84%EB%A1%9C%EB%93%9C/main_690x720.jpg"
                alt=""
                class="list-thumbnail"
              />
              <h4 class="spot-title">제라진 오프로드</h4>
              <p class="spot-desc">
                오프로드 탐험 후에는 이색적인 뱅듸못에서 인생사진 남길 수 있다!
              </p>
            </a>
          </div>
        </div>
        <div class="col-md-4 content-list-wrapper">
          <div class="content-item-wrapper">
            <a href="/destination/190?page=1">
              <img
                src="http://dantwortjejutovr360.s3.ap-northeast-2.amazonaws.com/places/%EB%9F%AC%EB%B8%8C%EB%9E%9C%EB%93%9C/190_main_690x720.jpg"
                alt=""
                class="list-thumbnail"
              />
              <h4 class="spot-title">러브랜드</h4>
              <p class="spot-desc">
                미성년자는 출입이 금지되어 있으니 주의하자!
              </p>
            </a>
          </div>
        </div>
        <div class="col-md-4 content-list-wrapper">
          <div class="content-item-wrapper">
            <a href="https://bit.ly/2X1xOBa" target="_blank">
              <img
                src="https://dantwortjejutovr360.s3.ap-northeast-2.amazonaws.com/banners/%EC%95%A0%ED%94%8C%EB%A7%9D%EA%B3%A0/jejurentcar_middle_200729.jpg"
                alt=""
                class="list-thumbnail"
              />
            </a>
          </div>
        </div>
        <div class="col-md-4 content-list-wrapper">
          <div class="content-item-wrapper">
            <a href="/destination/66?page=1">
              <img
                src="http://post.phinf.naver.net/MjAxNzA3MjFfMTAx/MDAxNTAwNTk5NTk1NDY4.OSdYReL8GmBzGjMuxPIBtgB-sLbUlNjqWcEyqg_FFqgg.0R4vVTvMbMphj5tCZtM6BvaEdTpxFWvheE2OUM9DALcg.JPEG/main_690x720_%286%29.jpg?type=w1200"
                alt=""
                class="list-thumbnail"
              />
              <h4 class="spot-title">함덕서우봉해변</h4>
              <p class="spot-desc">
                수심이 낮아 어린이들이 물놀이하기에 안성맞춤
              </p>
            </a>
          </div>
        </div>
        <div class="col-md-4 content-list-wrapper">
          <div class="content-item-wrapper">
            <a href="/destination/157?page=1">
              <img
                src="http://dantwortjejutovr360.s3.ap-northeast-2.amazonaws.com/places/%ED%99%94%EC%A1%B0%EC%9B%90/1544511236360-main.jpg"
                alt=""
                class="list-thumbnail"
              />
              <h4 class="spot-title">화조원</h4>
              <p class="spot-desc">
                입장료에 먹이 구입 비용 포함, 유모차는 무상 대여 가능!
              </p>
            </a>
          </div>
        </div>
        <div class="col-md-4 content-list-wrapper">
          <div class="content-item-wrapper">
            <a href="/destination/173?page=1">
              <img
                src="http://dantwortjejutovr360.s3.ap-northeast-2.amazonaws.com/places/%EB%B0%95%EB%AC%BC%EA%B4%80%EC%9D%80%EC%82%B4%EC%95%84%EC%9E%88%EB%8B%A4/173_main_690x720.jpg"
                alt=""
                class="list-thumbnail"
              />
              <h4 class="spot-title">박물관은 살아있다</h4>
              <p class="spot-desc">당신이 있어야 완성되는 작품!</p>
            </a>
          </div>
        </div>
        <div class="col-md-4 content-list-wrapper">
          <div class="content-item-wrapper">
            <a href="https://bit.ly/2XLZ338" target="_blank">
              <img
                src="https://dantwortjejutovr360.s3.ap-northeast-2.amazonaws.com/banners/%EC%A0%9C%EC%A3%BC%ED%8C%A8%EC%8A%A4%EB%A0%8C%ED%8A%B8%EC%B9%B4/jejurentcar_middle_200810.jpg"
                alt=""
                class="list-thumbnail"
              />
            </a>
          </div>
        </div>
      </template>
      <!-- 유동 콘텐츠 -->
      <div
        class="col-md-4 content-list-wrapper"
        v-for="spot in spots"
        :key="spot.id"
      >
        <div class="content-item-wrapper">
          <a :href="`/destination/${spot.id}?page=${page}`">
            <img
              :src="spot.thumbnail"
              :alt="spot.name"
              class="list-thumbnail"
            />
            <h4 class="spot-title">{{ spot.name }}</h4>
            <p class="spot-desc">{{ spot.summary }}</p>
          </a>
        </div>
      </div>
      <div class="row pagination-container">
        <div class="col-md-12">
          <paginate
            v-model="page"
            :page-count="spotsLastPage"
            :click-handler="fetchMoreData"
            :container-class="'pagination justify-content-center'"
            :page-class="'page-item'"
            :page-range="7"
            :prev-text="''"
            :next-text="''"
          >
          </paginate>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate';
import { mapState, mapActions } from 'vuex';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import CarouselComponent from '@/components/main/CarouselComponent.vue';

export default {
  components: {
    Paginate,
    LoadingSpinner,
    CarouselComponent,
  },
  data() {
    return {
      totalPage: null,
      totalRows: 0,
      page: 1,
      isLoading: false,
    };
  },
  // watch: {
  //   page() {
  //     this.reloadPageNum();
  //   },
  // },
  computed: {
    ...mapState(['spots', 'spotsLastPage']),
  },
  created() {
    if (!isNaN(Number(this.$route.query.page))) {
      this.page = Number(this.$route.query.page);
    } else {
      this.page = 1;
    }
    this.fetchData(this.page);
  },
  mounted() {
    // this.$nextTick(function () {
    //   for (let i = 0; i < document.querySelectorAll('.page-item').length; i++) {
    //     const element = document.querySelectorAll('.page-item')[i];
    //     element.classList.remove('active');
    //   }
    //   document
    //     .querySelectorAll('.page-item')
    //     [this.page - 1].classList.add('active');
    // });
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    reloadPageNum() {
      for (let i = 0; i < document.querySelectorAll('.page-item').length; i++) {
        const element = document.querySelectorAll('.page-item')[i];
        element.classList.remove('active');
      }
      document
        .querySelectorAll('.page-item')
        [this.page - 1].classList.add('active');
    },
    ...mapActions(['FETCH_SPOTS']),
    fetchData(page) {
      this.isLoading = true;
      this.FETCH_SPOTS(page);
      this.isLoading = false;
    },
    fetchMoreData(pageNum) {
      this.page = pageNum;
      this.fetchData(pageNum);
      this.$router.replace({ query: { ...this.$route.query, page: pageNum } });
      window.scrollTo(0, 0);
      // for (let i = 0; i < document.querySelectorAll('.page-item').length; i++) {
      //   const element = document.querySelectorAll('.page-item')[i];
      //   element.classList.remove('active');
      // }
      // document
      //   .querySelectorAll('.page-item')
      //   [this.page - 1].classList.add('active');
    },
  },
};
</script>

<style scoped>
.spot-title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #333;
}
.spot-desc {
  margin-bottom: 0;
  font-size: 16px;
  color: #333;
  word-break: keep-all;
}
.content-item-wrapper {
  margin-bottom: 50px;
}
.list-thumbnail {
  border-radius: 5px;
}
</style>
