<template>
  <div class="main-slider">
    <carousel
      :autoplay="true"
      :perPage="1"
      :loop="true"
      :autoplayTimeout="3000"
      :centerMode="true"
      :paginationPosition="'bottom-overlay'"
    >
      <slide
        ><a
          href="https://bit.ly/2DF9VZN"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://dantwortjejutovr360.s3.ap-northeast-2.amazonaws.com/banners/%EC%A0%9C%EC%A3%BC%ED%8C%A8%EC%8A%A4%EB%A0%8C%ED%8A%B8%EC%B9%B4/jejurentcar_top_200810.jpg"
            alt=""
          /> </a
      ></slide>
      <slide
        ><a
          href="https://bit.ly/303luBg"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://dantwortjejutovr360.s3.ap-northeast-2.amazonaws.com/banners/%EB%B3%B4%EB%9D%BC%EC%A0%9C%EC%A3%BC%EC%9D%B8%EC%8A%A4%ED%83%80%EA%B7%B8%EB%9E%A8/200714_banner_%ED%94%8C%EB%A0%88%EC%9D%B4%ED%8A%B8.jpg"
            alt=""
          /> </a
      ></slide>
      <slide
        ><a
          href="https://bit.ly/30bOjg0"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://dantwortjejutovr360.s3.ap-northeast-2.amazonaws.com/banners/%EC%A0%9C%EC%A3%BC%ED%99%88%EC%B9%B4%ED%8E%98/01.jpg"
            alt=""
          /> </a
      ></slide>
      <slide
        ><a
          href="https://bit.ly/334TMqI"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://dantwortjejutovr360.s3.ap-northeast-2.amazonaws.com/banners/%EC%95%A0%ED%94%8C%EB%A7%9D%EA%B3%A0/jejurentcar_top_200729.jpg"
            alt=""
          /> </a
      ></slide>
    </carousel>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.main-slider {
  margin-bottom: 50px;
}
.main-slider img {
  width: 100%;
}
</style>
